<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-listenText-course">
    <!-- <scaleWrap> -->
    <div class="home" ref="home">
      <img class="logo" src="@/assets/index/img-logo.png" />
      <img @click="backHandler" class="btn-back" src="@/assets/index/btn-back.png" />
      <img @click="playHandler" v-if="!playing" class="btn-play" src="@/assets/listenWord/btn-play.png" />
      <img @click="pauseHandler" v-if="playing" class="btn-play" src="@/assets/listenText/btn-pause.png" />
      <!-- <img class="game-logo" src="@/assets/index/img-ztrz-new.png"/> -->
      <div class="word-container">
        <div ref="wordPlayBox" class="word-play-box">
          <p class="title">连听课文，训练直映听觉力。</p>
          <div class="poster"></div>
        </div>
      </div>
      <audio id="myAudio" ref="myAudio" preload></audio>
      <click-audio ref="clickAudio"></click-audio>
    </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import horizontalScreen from '@/components/horizontal-screen'
import ClickAudio from '@/components/clickAudio.vue'
import {isEmpty} from 'lodash'
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'ListenTextCourse',
  components: {
    horizontalScreen, ClickAudio
  },
  computed:{
    ...mapGetters(['cardGradeIds'])
  },
  data() {
    return {
      listenTextRadio: [],
      audioList: [],
      playing: false
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    isEmpty,
     ...mapActions(['getListenBook']),
    async fetchList() {
      this.$loading.show('数据加载中。。。')
      try {
        const res = await this.getListenBook({
          gradeId: this.cardGradeIds
        })
        this.listenTextRadio = res.result.map(({url}) => url)
      } finally {
        this.$loading.hide()
      }
    },
    backHandler() {
      this.$refs.clickAudio.playAudio()
      this.$router.back()
      window.wx.miniProgram.navigateBack()
    },
    playAudio () {
      const myAudio = this.$refs.myAudio
      myAudio.src=this.audioList[0]
      myAudio.play()
      this.playing = true
      let index = 0
      myAudio.onended = () => {
        console.log(index, this.audioList)
        if(index < this.audioList.length - 1){
          myAudio.src=this.audioList[index+1];
          myAudio.play()
          index++;
        } else {
          this.replayHandler()
          // this.playing = false
        }
      };
    },
    // 暂停
    pauseHandler() {
      this.$refs.clickAudio.playAudio()
      this.$refs.myAudio.pause()
      this.playing = false
    },
    replayHandler() {
      this.audioList = this.listenTextRadio
      this.playAudio()
    },
    // 播放
    playHandler() {
      this.$refs.clickAudio.playAudio()
      if (this.playing === false) {
        this.playing = true
      }
      if (this.$refs.myAudio.src) {
        this.$refs.myAudio.play()
      } else {
        this.audioList = this.listenTextRadio
        this.playAudio()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-listenText-course {

  // font-family: 'Alibaba-PuHuiTi-Bold';
  // font-family: 'FZHuPo-M04S';
  .word-container {
    display: flex;
    width: 70%;
    margin: 0 auto;
    height: 100%;
    position: relative;

    .word-play-box {
      width: 100%;
      height: auto;
      // height: 285.7px;
      // height: calc(100vw - 60px);
      background: url(~@/assets/word/bg-window.png) center center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      overflow: hidden;
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      >img {
        margin-top: 6%;
        width: 70%;
        height: auto;
      }

      .title {
        text-align: center;
        position: absolute;
        width: 100%;
        top: 3%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 36px;
        color: #000;
      }

      .poster {
        width: 80%;
        height: 80%;
        background-image: url('~@/assets/listenText/img-poster.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        margin-top: 30px;
      }
    }
  }

  audio {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: -1;
  }

  .home {
    height: 100%;
    width: 100%;
    background: url(~@/assets/listenWord/bg-play.png) center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  // .logo {
  //   width: 28px;
  //   left: 5px;
  //   top: 10PX;
  //   position: fixed;
  // }
  .btn-back {
    position: fixed;
    width: 65.5px;
    right: 10PX;
    top: 5PX;
  }

  .btn-play {
    position: fixed;
    width: 83.5px;
    height: 55.5px;
    right: 10px;
    bottom: 20px;
  }

}</style>
